<script>
import { Line } from 'vue-chartjs'

export default {
    extends: Line,
    props: {
        data: Array,
        pointRadius: Number,
        xAxes_ticks: Boolean,
        labels: Array
    },
    data: () => ({
        chartdata: {
            labels: [],
            datasets: [
                {
                    borderWidth: 2,
                    borderColor: '#007db5',
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointBorderColor: '#007db5',
                    pointBackgroundColor: '#fff',
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#007db5',
                    backgroundColor: null,
                    data: []
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        alignment: 'start',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        drawTicks: false,
                        // color: "#fafafa",
                    },
                    ticks: {
                        display: true,
                        beginAtZero: true
                    },
                }]
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return `${tooltipItem.value} Users`;
                    },
                },
            },
        }
    }),
    watch: {
        data() {
            this.chartdata.datasets[0].data = this.data;
            this.options.scales.xAxes[0].ticks.display = this.xAxes_ticks;
            if (this.xAxes_ticks) {
                this.options.layout.padding.left = 20;
                this.options.layout.padding.right = 20;
                this.options.layout.padding.top = 20;
                this.options.layout.padding.bottom = 20;
            }
            this.chartdata.labels = this.labels;
            this.renderChart(this.chartdata, this.options);
        }
    },
    mounted () {
        let ctx = this.$refs.canvas.getContext('2d');
        let gradientFill = ctx.createLinearGradient(0, 0, 0, 450);
        gradientFill.addColorStop(0, 'rgba(0, 125, 181, 0.5)');
        gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)');
        this.chartdata.datasets[0].backgroundColor = gradientFill;
        this.chartdata.datasets[0].data = this.data;
        this.chartdata.datasets[0].pointRadius = this.pointRadius;
        this.options.scales.xAxes[0].ticks.display = this.xAxes_ticks;
        if (this.xAxes_ticks) {
            this.options.layout.padding.left = 20;
            this.options.layout.padding.right = 20;
            this.options.layout.padding.top = 20;
            this.options.layout.padding.bottom = 20;
        }
        this.chartdata.labels = this.labels
        this.renderChart(this.chartdata, this.options);
    },
};
</script>

<style lang="scss">

</style>
